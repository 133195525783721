import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import appStore from '@/store'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/login/index.vue'),
    meta: {
      title: '登录' // 通过meta动态修改html页面的title
    }
  },
  {
    path: '/home',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/home/index.vue'),
    meta: {
      title: '患者就诊平台'
    }
  },
  {
    path: '/cardQuery',
    name: 'cardQuery',
    component: () => import(/* webpackChunkName: "about" */ '../views/card/query/index.vue'),
    meta: {
      title: '绑定就诊人'
    }
  },
  {
    path: '/cardRegister',
    name: 'cardRegister',
    component: () => import(/* webpackChunkName: "about" */ '../views/card/register/index.vue'),
    meta: {
      title: '在线建卡'
    }
  },
  {
    path: '/cardDetail',
    name: 'cardDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/card/detail/index.vue'),
    meta: {
      title: '我的就诊卡'
    }
  },
  {
    path: '/cardManage',
    name: 'cardManage',
    component: () => import(/* webpackChunkName: "about" */ '../views/card/manage/index.vue'),
    meta: {
      title: '就诊卡管理'
    }
  },
  {
    path: '/inpatientBind',
    name: 'inpatientBind',
    component: () => import(/* webpackChunkName: "about" */ '../views/card/inpatient/index.vue'),
    meta: {
      title: '住院绑定'
    }
  },
  {
    path: '/addressList',
    name: 'addressList',
    component: () => import(/* webpackChunkName: "about" */ '../views/card/addr/addressList/index.vue'),
    meta: {
      title: '地址列表'
    }
  },
  {
    path: '/addressEdit',
    name: 'addressEdit',
    component: () => import(/* webpackChunkName: "about" */ '../views/card/addr/addressEdit/index.vue'),
    meta: {
      title: '地址编辑'
    }
  },
  {
    path: '/opoReg',
    name: 'opoReg',
    component: () => import(/* webpackChunkName: "about" */ '../views/opo/register/dept/index.vue'),
    meta: {
      title: '科室选择'
    }
  },
  {
    path: '/opoSchedule',
    name: 'opoSchedule',
    component: () => import(/* webpackChunkName: "about" */ '../views/opo/register/schedule/index.vue'),
    meta: {
      title: ''
    }
  },
  {
    path: '/regConfirm',
    name: 'regConfirm',
    component: () => import(/* webpackChunkName: "about" */ '../views/opo/register/confirm/index.vue'),
    meta: {
      title: '选择时间'
    }
  },
  {
    path: '/regRecord',
    name: 'regRecord',
    component: () => import(/* webpackChunkName: "about" */ '../views/opo/register/record/index.vue'),
    meta: {
      title: '挂号记录'
    }
  },
  {
    path: '/docInfo',
    name: 'docInfo',
    component: () => import(/* webpackChunkName: "about" */ '../views/opo/register/doc/index.vue'),
    meta: {
      title: '医生主页'
    }
  },
  {
    path: '/opoReport',
    name: 'opoReport',
    component: () => import(/* webpackChunkName: "about" */ '../views/opo/report/list.vue'),
    meta: {
      title: '我的检查检验'
    }
  },
  {
    path: '/lisReport',
    name: 'lisReport',
    component: () => import(/* webpackChunkName: "about" */ '../views/opo/report/lis.vue'),
    meta: {
      title: '检验报告'
    }
  },
  {
    path: '/pacsReport',
    name: 'pacsReport',
    component: () => import(/* webpackChunkName: "about" */ '../views/opo/report/pacs.vue'),
    meta: {
      title: '检查报告'
    }
  },
  {
    path: '/opoPayList',
    name: 'opoPayList',
    component: () => import(/* webpackChunkName: "about" */ '../views/opo/pay/list/index.vue'),
    meta: {
      title: '门诊缴费'
    }
  },
  {
    path: '/opoPayConfirm',
    name: 'opoPayConfirm',
    component: () => import(/* webpackChunkName: "about" */ '../views/opo/pay/confirm/index.vue'),
    meta: {
      title: '缴费确认'
    }
  },
  {
    path: '/opoPayDetail',
    name: 'opoPayDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/opo/pay/detail/index.vue'),
    meta: {
      title: '处方明细'
    }
  },
  //#region 问诊单上传
  {
    path: '/uploadHealthForm',
    name: 'uploadHealthForm',
    component: () => import(/* webpackChunkName: "about" */ '../views/opo/healthFrom/index.vue'),
    meta: {
      title: ''
    }
  },   {
    path: '/HealthFormList',
    name: 'HealthFormList',
    component: () => import(/* webpackChunkName: "about" */ '../views/opo/healthFrom/list.vue'),
    meta: {
      title: '健康信息列表'
    }
  }, 
  //#endregion


  //住院模块
  {
    path: '/ipoDailyList',
    name: 'ipoDailyList',
    component: () => import(/* webpackChunkName: "about" */ '../views/ipo/dailyList/list/index.vue'),
    meta: {
      title: '住院清单'
    }
  },
  {
    path: '/ipoDailyDetail',
    name: 'ipoDailyDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/ipo/dailyList/detail/index.vue'),
    meta: {
      title: '住院日清单'
    }
  },
  {
    path: '/ipoRecord',
    name: 'ipoRecord',
    component: () => import(/* webpackChunkName: "about" */ '../views/ipo/inRecord/index.vue'),
    meta: {
      title: '住院记录'
    }
  },
  {
    path: '/ipoInfo',
    name: 'ipoInfo',
    component: () => import(/* webpackChunkName: "about" */ '../views/ipo/inInfo/index.vue'),
    meta: {
      title: '在院信息'
    }
  },
  {
    path: '/ipoPay',
    name: 'ipoPay',
    component: () => import(/* webpackChunkName: "about" */ '../views/ipo/pay/index.vue'),
    meta: {
      title: '预交金充值'
    }
  },
  //体检模块
  {
    path: '/chkPackageList',
    name: 'chkPackageList',
    component: () => import(/* webpackChunkName: "about" */ '../views/chk/register/package/list/index.vue'),
    meta: {
      title: '体检套餐'
    }
  },
  {
    path: '/chkPackageDetail',
    name: 'chkPackageDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/chk/register/package/detail/info/index.vue'),
    meta: {
      title: '体检套餐明细'
    }
  },
  {
    path: '/chkPackageDetailInsert',
    name: 'chkPackageDetailInsert',
    component: () => import(/* webpackChunkName: "about" */ '../views/chk/register/package/detail/insert/index.vue'),
    meta: {
      title: '体检项目新增'
    }
  },
  {
    path: '/chkRegConfirm',
    name: 'chkRegConfirm',
    component: () => import(/* webpackChunkName: "about" */ '../views/chk/register/confirm/index.vue'),
    meta: {
      title: '体检套餐确认'
    }
  },
  {
    path: '/chkRegSelectDate',
    name: 'chkRegSelectDate',
    component: () => import(/* webpackChunkName: "about" */ '../views/chk/register/selectDate/index.vue'),
    meta: {
      title: '体检选择时间'
    }
  },
  {
    path: '/chkPayList',
    name: 'chkPayList',
    component: () => import(/* webpackChunkName: "about" */ '../views/chk/pay/list/index.vue'),
    meta: {
      title: '体检支付列表'
    }
  },
  {
    path: '/chkPayDetail',
    name: 'chkPayDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/chk/pay/detail/index.vue'),
    meta: {
      title: '体检支付确认'
    }
  },
  {
    path: '/chkReportList',
    name: 'chkReportList',
    component: () => import(/* webpackChunkName: "about" */ '../views/chk/report/list/index.vue'),
    meta: {
      title: '体检报告列表'
    }
  },
  {
    path: '/chkReportDetail',
    name: 'chkReportDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/chk/report/detail/index.vue'),
    meta: {
      title: '体检报告'
    }
  },
  {
    path: '/chkOccupationhealthForm',
    name: 'chkOccupationhealthForm',
    component: () => import(/* webpackChunkName: "about" */ '../views/chk/occupation/healthForm/index.vue'),
    meta: {
      title: '职业病体检录入'
    }
  },

  //在线问诊
  {
    path: '/opoOnlineDept',
    name: 'opoOnlineDept',
    component: () => import(/* webpackChunkName: "about" */ '../views/opo/online/dept/index.vue'),
    meta: {
      title: '在线问诊'
    }
  },
  {
    path: '/opoOnlineDoc',
    name: 'opoOnlineDoc',
    component: () => import(/* webpackChunkName: "about" */ '../views/opo/online/doc/index.vue'),
    meta: {
      title: '在线问诊'
    }
  },
  {
    path: '/opoOnlineConfirm',
    name: 'opoOnlineConfirm',
    component: () => import(/* webpackChunkName: "about" */ '../views/opo/online/confirm/index.vue'),
    meta: {
      title: '在线问诊'
    }
  },
  {
    path: '/opoOnlineChat',
    name: 'opoOnlineChat',
    component: () => import(/* webpackChunkName: "about" */ '../views/opo/online/chat/index.vue'),
    meta: {
      title: '在线问诊'
    }
  },
  {
    path: '/opoOnlineHealthForm',
    name: 'opoOnlineHealthForm',
    component: () => import(/* webpackChunkName: "about" */ '../views/opo/online/chat/healthFrom/index.vue'),
    meta: {
      title: '填写健康信息'
    }
  }, 
  {
    path: '/opoOnlineHealthFormAdd',
    name: 'opoOnlineHealthFormAdd',
    component: () => import(/* webpackChunkName: "about" */ '../views/opo/online/chat/healthFrom/add.vue'),
    meta: {
      title: '填写健康信息'
    }
  },
  {
    path: '/onlineResult',
    name: 'onlineResult',
    component: () => import(/* webpackChunkName: "about" */ '../views/opo/online/result/index.vue'),
    meta: {
      title: '问诊支付结果'
    }
  },  
  {
    path: '/onlineRecord',
    name: 'onlineRecord',
    component: () => import(/* webpackChunkName: "about" */ '../views/opo/online/record/index.vue'),
    meta: {
      title: '咨询记录'
    }
  },

  //在线问诊 医生端 
  {
    path: '/docOnlineRecord',
    name: 'docOnlineRecord',
    component: () => import(/* webpackChunkName: "about" */ '../views/doc/online/record/index.vue'),
    meta: {
      title: '咨询列表'
    }
  },
  {
    path: '/docOnlineChat',
    name: 'docOnlineChat',
    component: () => import(/* webpackChunkName: "about" */ '../views/doc/online/chat/index.vue'),
    meta: {
      title: '咨询信息'
    }
  },
  {
    path: '/docLogin',
    name: 'docLogin',
    component: () => import(/* webpackChunkName: "about" */ '../views/doc/login/index.vue'),
    meta: {
      title: '登录'
    }
  },
  {
    path: '/docOnlineHome',
    name: 'docOnlineHome',
    component: () => import(/* webpackChunkName: "about" */ '../views/doc/online/home/index.vue'),
    meta: {
      title: '首页'
    }
  },
  {
    path: '/docOnlineFastRxMain',
    name: 'docOnlineFastRxMain',
    component: () => import(/* webpackChunkName: "about" */ '../views/doc/online/fastRx/main/index.vue'),
    meta: {
      title: '快速处方'
    }
  },
  {
    path: '/docOnlineFastRxMain',
    name: 'docOnlineFastRxMain',
    component: () => import(/* webpackChunkName: "about" */ '../views/doc/online/fastRx/main/index.vue'),
    meta: {
      title: '快速处方'
    }
  },

  {
    path: '/docOnlineInquiryTextMain',
    name: 'docOnlineInquiryTextMain',
    component: () => import(/* webpackChunkName: "about" */ '../views/doc/online/inquiry/text/main/index.vue'),
    meta: {
      title: '图文问诊'
    }
  },
  {
    path: '/docOnlineInquiryTextDetail',
    name: 'docOnlineInquiryTextDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/doc/online/inquiry/text/detail/index.vue'),
    meta: {
      title: '图文问诊'
    }
  },
  {
    path: '/docOnlineFastRxCreateRxMain',
    name: 'docOnlineFastRxCreateRxMain',
    component: () => import(/* webpackChunkName: "about" */ '../views/doc/online/fastRx/createRx/main/index.vue'),
    meta: {
      title: '开处方'
    }
  },
  {
    path: '/docOnlineFastRxCreateRxDetail',
    name: 'docOnlineFastRxCreateRxDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/doc/online/fastRx/createRx/detail/index.vue'),
    meta: {
      title: '编辑处方'
    }
  },
  {
    path: '/docOnlineFastRxAddDrugDetail',
    name: 'docOnlineFastRxAddDrugDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/doc/online/fastRx/addDrug/detail/index.vue'),
    meta: {
      title: '新增药品'
    }
  },
  {
    path: '/docOnlineFastRxEditDosage',
    name: 'docOnlineFastRxEditDosage',
    component: () => import(/* webpackChunkName: "about" */ '../views/doc/online/fastRx/editDosage/index.vue'),
    meta: {
      title: '用法用量'
    }
  },
  {
    path: '/docOnlineFastRxAddDiagMain',
    name: 'docOnlineFastRxAddDiagMain',
    component: () => import(/* webpackChunkName: "about" */ '../views/doc/online/fastRx/addDiag/main/index.vue'),
    meta: {
      title: '当前诊断'
    }
  },
  {
    path: '/docOnlineFastRxAddDiagDetail',
    name: 'docOnlineFastRxAddDiagDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/doc/online/fastRx/addDiag/detail/index.vue'),
    meta: {
      title: '新增诊断'
    }
  },

  {
    path: '/opoOnlineRxPayList',
    name: 'opoOnlineRxPayList',
    component: () => import(/* webpackChunkName: "about" */ '../views/opo/online/rxPay/list/index.vue'),
    meta: {
      title: '我的处方'
    }
  },
  {
    path: '/opoOnlineRxPayConfirm',
    name: 'opoOnlineRxPayConfirm',
    component: () => import(/* webpackChunkName: "about" */ '../views/opo/online/rxPay/confirm/index.vue'),
    meta: {
      title: '处方缴费'
    }
  },
  {
    path: '/opoOnlineRxPayDetail',
    name: 'opoOnlineRxPayDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/opo/online/rxPay/detail/index.vue'),
    meta: {
      title: '处方详情'
    }
  },

  //支付
  {
    path: '/paytest',
    name: 'paytest',
    component: () => import(/* webpackChunkName: "about" */ '../views/pay/index.bak.vue'),
    meta: {
      title: '收银台'
    }
  },
  
 {
    path: '/pay',
    name: 'pay',
    component: () => import(/* webpackChunkName: "about" */ '../views/pay/index.vue'),
    meta: {
      title: '收银台'
    }
  }, 
  {
    path: '/ordertest',
    name: 'ordertest',
    component: () => import(/* webpackChunkName: "about" */ '../views/pay/testCreat.vue'),
    meta: {
      title: '测试订单'
    }
  },
  {
    path: '/regResult',
    name: 'regResult',
    component: () => import(/* webpackChunkName: "about" */ '../views/opo/register/result/index.vue'),
    meta: {
      title: '挂号详情'
    }
  },
  {
    path: '/chkPayResult',
    name: 'chkPayResult',
    component: () => import(/* webpackChunkName: "about" */ '../views/chk/pay/result/index.vue'),
    meta: {
      title: '体检支付结果'
    }
  },
  {
    path: '/opoPayResult',
    name: 'opoPayResult',
    component: () => import(/* webpackChunkName: "about" */ '../views/opo/pay/result/index.vue'),
    meta: {
      title: '缴费详情'
    }
  },
  {
    path: '/ipoPayResult',
    name: 'ipoPayResult',
    component: () => import(/* webpackChunkName: "about" */ '../views/ipo/result/index.vue'),
    meta: {
      title: '缴费详情'
    }
  },


  //院内信息
  {
    path: '/hospitalInfo',
    name: 'hospitalInfo',
    component: () => import(/* webpackChunkName: "about" */ '../views/info/hospital/index.vue'),
    meta: {
      title: '医院信息'
    }
  },

  //公告咨询
  {
    path: '/news',
    name: 'news',
    component: () => import(/* webpackChunkName: "about" */ '../views/news/index.vue'),
    meta: {
      title: '新闻公告'
    }
  },

  //我的
  {
    path: '/mine',
    name: 'mine',
    component: () => import(/* webpackChunkName: "about" */ '../views/mine/index.vue'),
    meta: {
      title: '我的'
    }
  },

  //临时
  {
    path: '/opoReportBak',
    name: 'opoReportBak',
    component: () => import(/* webpackChunkName: "about" */ '../views/opo/report-bak/list.vue'),
    meta: {
      title: '我的检查检验'
    }
  },
  {
    path: '/lisReportBak',
    name: 'lisReportBak',
    component: () => import(/* webpackChunkName: "about" */ '../views/opo/report-bak/lis.vue'),
    meta: {
      title: '检验报告'
    }
  },
  {
    path: '/pacsReportBak',
    name: 'pacsReportBak',
    component: () => import(/* webpackChunkName: "about" */ '../views/opo/report-bak/pacs.vue'),
    meta: {
      title: '检查报告'
    }
  },
  {
    path: '/opoPacsVideo',
    name: 'opoPacsVideo',
    component: () => import(/* webpackChunkName: "about" */ '../views/opo/report/video/pacs.vue'),
    meta: {
      title: '检查视频'
    }
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

const ignoreRouter = ['login','opoReportBak','lisReportBak','pacsReportBak']
const ignoreOPORouter = [ 'opoReportBak','lisReportBak','pacsReportBak', 'uploadHealthForm','regResult','login','home','cardQuery','news','mine','hospitalInfo','chkOccupationhealthForm','cardRegister','opoReg','opoSchedule','docInfo','opoOnlineDept','opoOnlineDoc','chkPackageList','chkPackageDetail','chkPackageDetailInsert']
const ignoreIPORouter = ['login','home']
//路由守卫
router.beforeEach((to, from, next) => {
  let toPath = to.name?.toString() == undefined ? '' : to.name?.toString()
  let isAuth = appStore.userStore.getAuthenticated; //登录验证
  if (isAuth || ignoreRouter.indexOf(toPath) !== -1) {
    let isBind = appStore.userStore.getBind; //绑定卡号验证
    if(isBind || ignoreOPORouter.indexOf(toPath) !== -1)
    {
      if (typeof (to.meta?.title) === 'string') {
        document.title = to.meta?.title;
      }
      next();
    }
    else
    {
      router.push({
        name: 'cardQuery', 
        query: { toPath: toPath, param: JSON.stringify(to.query)}
      })
    }
  } else {
    if(to.query)
    {
      router.push({
        name: 'login',
        query: { toPath: toPath, param: JSON.stringify(to.query)}
      })
    }  
  }
})
export default router
