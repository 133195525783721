
import { defineComponent } from 'vue'
import { storeToRefs } from 'pinia';
import appStore from '@/store'
import { testapi } from './api'; 

export default defineComponent({
  //el:'#AboutView', // 副作用 (触发组件外的影响) class选择器或者id选择器
  name: 'AppMain', // 全局感知 (要求组件以外的知识)
  //parent:'', // 全局感知 (要求组件以外的知识)
  //functional: false, // 组件类型 (更改组件的类型) 添加 functional: true 之后就是函数式组件 functional 1.需要通过编程实现在多种组件中选择一种。2.children、props 或者 data 在传递给子组件之前，处理它们。
  //delimiter:['${','}'], //模板修改器 (改变模板的编译方式) delimiters的作用是改变我们插值的符号,现在我们的插值形式就变成了${},代替了{{ }},
  //comments: true, // 模板修改器 (改变模板的编译方式)  当设置为true时 将会保留并渲染模板中的html注释,默认行为是舍弃它们
  components: { // 模板依赖 (模板内使用的资源) 组件
  },
  directives: { // 模板依赖 (模板内使用的资源) 全局自定义指令 使用方法 v-local-test = '参数'
    // "local-test": function(el, binding, vnode) {
    //     /** el可以获取当前dom节点，并且进行编译，也可以操作事件 **/
    //     /** binding指的是一个对象，一般不用 **/
    //     /** vnode 是 Vue 编译生成的虚拟节点 **/
    //     el.style.border = "1px solid red"; //操作style所有样式
    //     console.log(el.value); //获取v-model的值
    //     console.log(el.dataset.name); //data-name绑定的值，需要el.dataset来获取
    //     console.log(vnode); //获取当前路由信息 //.$route
    // }
  },
  filters: { // 模板依赖 (模板内使用的资源) 过滤器

  },
  data() { // 本地状态 (本地的响应式 property)
    return {
    }
  },
  computed: { // 本地状态 (本地的响应式 property) //计算函数，复杂的计算都应该放在这里，尽量不要直接在模板语法里面计算，在此处计算的值是会动态变的，即计算的数中有一个值变了，最终结果也会跟着变，类似于封装的计算函数

  },
  setup() {
    const main = appStore.mainStore
    return {
      main
    }
  },
  watch: { // 事件 (通过响应式事件触发的回调) 响应数据的变化
    // cityName: {
    //   handler(newName, oldName) {
    //   // ...
    // },
    // deep: true,
    // immediate: true
    // }
  },
  beforeCreate() { //生命周期钩子 (按照它们被调用的顺序)

  },
  created() {

  },
  beforeMount() {

  },
  mounted() {
    testapi()
  },
  beforeUpdate() {

  },
  updated() {

  },
  activated() {

  },
  deactivated() {

  },
  beforeDestroy() {

  },
  destroyed() {

  },
  methods: { // 非响应式的 property (不依赖响应系统的实例 property)
    refundCard() {
      appStore.userStore.setAuth(false)
      appStore.userStore.setUser({ cardNo: '', inPatientNo: '', name: '', age: 0, sex: 0, feeType: '' })
      this.$router.push({
        name: 'home'
      })
    },
    goBack() {
      this.$router.back()
    },
    goHome() {
      this.$router.push({ name: 'minhome1' })
    }
  },
  //template: '',// 渲染 (组件输出的声明式描述) 模板
  render() { // 渲染 (组件输出的声明式描述) 创建模板

  },
  renderError() { }
})
