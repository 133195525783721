import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import moment from 'moment'
import pinia from '@/store/store'
import { registerStore } from '@/store'
//import components from '@/components'
import 'lib-flexible' 
import Vant from 'vant'
import 'vant/lib/index.css';
import './assets/aliIconfont/iconfont.js'
require('promise.prototype.finally').shim();
// 引入全局样式
import '@/styles/index.css'


const app = createApp(App)
app.use(Vant)
app.use(pinia)
app.use(router)
//app.use(components)
app.config.globalProperties.$moment = moment

registerStore();
app.mount('#app')
