//挂号信息
import { defineStore } from 'pinia'
export const chkItemStore = defineStore('chkItem', { 
    state: () => {
        return {
            chkItem:{chkItemId: "",price:0,item:Array<any>(),addItem:Array<any>()}
        }
    },
    getters: {
       
    },
    actions: {
        setItem(items:Array<any>){
            this.chkItem.item = items;
            this.chkItem.addItem = [];
            this.chkItem.price = 0;
            this.chkItem.item.forEach(item=>{
                this.chkItem.price += item.real_price;
                this.chkItem.chkItemId = item.package_id;
            })
        },
        setAddItem(items:Array<any>){
            this.chkItem.price = 0;
            this.chkItem.addItem = items;
            this.chkItem.addItem.forEach(item=>{
                this.chkItem.price += item.price;
            })
            this.chkItem.item.forEach(item=>{
                this.chkItem.price += item.real_price;
            })
        },
    },
    // 所有数据持久化
    // persist: true,
    //持久化存储插件其他配置
    persist: {
        // 修改存储中使用的键名称，默认为当前 Store的 id
        key: 'chkItem_key',
        // 修改为 sessionStorage，默认为 localStorage
        storage: window.sessionStorage,
        // 部分持久化状态的点符号路径数组，[]意味着没有状态被持久化(默认为undefined，持久化整个状态)
        // paths: ['token'],
    },
})