import { defineStore } from 'pinia'
import { ref } from 'vue'
import { patientType, userType}  from '@/model'
import { getBindDefPat, getBindPatients, editDefPat } from '@/api'
import { showFailToast, showSuccessToast } from 'vant';

export const userStore = defineStore('user', { 
    state: () => {
        return {
            isAuthenticated: false,
            user: ref<userType>(),
            tokenExpire : new Date,
            token : '',
            isbind : false,
            patients: ref<Array<patientType>>(),
            defPatient: ref<patientType>(),
            docCode:ref<string>("")
        }
    },
    getters: {
        getAuthenticated:(state)=>state.isAuthenticated,
        getUser:(state)=>state.user,
        getBind:(state)=>state.isbind,
        getPatients:(state)=>{ return state.patients },
        getDefPatient:(state)=>{ 
            if(state.isbind) {
                return state.defPatient
            }else
            {
                return null
            }
        },
        getDoc:(state)=> state.docCode
    },
    actions: {
        clearToken(){

        },
        updateTokenExpire(expiredate:Date){

        },
        updateToken(token:string){

        },
        setAuth(isAuth:boolean){
            if(isAuth)
            {
                this.isAuthenticated = isAuth;
            }else{
                this.isAuthenticated = false;
            }
        },
        setUser(user:userType | null){
            if(user)
            {
                this.user = user
            }
            else{
                this.user = undefined
            }
        },
        async setPatients(){
            if (this.user?.openId) {
                let params = { OpenId: this.user.openId }
                let res = await getBindPatients(params);
                if (res.data.success) {
                    let resPatients = res.data.response;
                    if (resPatients!= null) {
                        this.patients = [];
                        this.defPatient = undefined;
                        this.isbind = false;
                        resPatients.forEach((patient:any) => {
                            this.patients?.push({ idCard: patient.IDCard, name: patient.Name, phone: patient.Phone, cardno: patient.CardNo, inpatientNO: patient.InpatientNo, sex: '', birthday: new Date, default: patient.IsSelect })  
                            if(patient.IsSelect){
                                this.defPatient = { idCard: patient.IDCard, name: patient.Name, phone: patient.Phone, cardno: patient.CardNo, inpatientNO: patient.InpatientNo, sex: '', birthday: new Date, default: patient.IsSelect };
                                this.isbind = true;
                            }
                        });
                    }
                }
            }
        },
        setDefPatient(cardno:string){
            this.patients?.forEach(item => {
                if(cardno === item.cardno){
                    this.defPatient = item;
                    item.default = 1;
                }
                else{
                    item.default = 0;
                }
            });
            if (this.user?.openId) {
                let params = { OpenId: this.user.openId, CardNo: cardno}
                editDefPat(params).then((res) => {
                    if (!res.data.success) {
                        console.log('设置默认患者失败' + res.data.msg) 
                    }
                }).catch((err) => {
                    console.log('设置默认患者失败' + err)
                });
            }          
        },
        setInPatientNo(inpatientNO: string){
            if(this.defPatient)
            {
                this.defPatient.inpatientNO = inpatientNO;
                var def = this.patients?.find(a=>a.cardno == this.defPatient?.cardno);
                if(def)
                {
                    def.inpatientNO = inpatientNO;
                }
            }           
        },
        setDoc(code:string){
            this.docCode = code;
        }
    },        
    // // 所有数据持久化
    // persist: true,
    //持久化存储插件其他配置
    persist: {
        // 修改存储中使用的键名称，默认为当前 Store的 id
        key: 'user_key',
        // 修改为 sessionStorage，默认为 localStorage
        storage: window.localStorage,
        // 部分持久化状态的点符号路径数组，[]意味着没有状态被持久化(默认为undefined，持久化整个状态)
        // paths: ['token'],
    },
})