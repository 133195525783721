import { defineStore } from 'pinia'
import { ref } from 'vue'
import { }  from '@/model'
import { } from '@/api'
import { showFailToast, showSuccessToast } from 'vant';

export const rxStore = defineStore('rx', { 
    state: () => {
        return {
            outTradeNo: ref<any>(),
            clientNo: ref<any>(),
            seeNo: ref<any>(),
            patient: ref<any>(),
            doct: ref<any>(),
            drugs: ref(Array<any>()),
            diags: ref(Array<any>())
        }
    },
    getters: {
        GetOutTradeNo:(state)=> state.outTradeNo,
        GetClientNo:(state)=> state.clientNo,
        GetSeeNo:(state)=> state.seeNo,
        GetDrugs:(state)=> state.drugs,
        GetDiags:(state)=> state.diags
    },
    actions: {
        clearDrug(){
            this.drugs = [];
        },
        addDrug(item:any){
            this.drugs.push(item);
            if(item.base.ClassCode == 'PCC')
            {
                this.drugs.forEach((element:any)=>{
                    element.usage = item.usage;
                    element.frequency = item.frequency;
                    element.days = item.days;
                })
            }
        },
        updateDrug(item:any){
            var t = this.drugs.find(a=>a.id == item.id);
            t = item;
            if(item.base.ClassCode == 'PCC')
            {
                this.drugs.forEach((element:any)=>{
                    element.usage = item.usage;
                    element.frequency = item.frequency;
                    element.days = item.days;
                })
            }
        },
        deleteDrug(item:any){
            this.drugs.splice(item, 1);
        },
        setPatient(patient:any){
            this.patient = patient;
        },
        setdoct(doct:any){
            this.doct = doct;
        },
        setOutTradeNo(outTradeNo:any,clientNo:any,seeNo:any){
            this.outTradeNo = outTradeNo;
            this.seeNo = seeNo;
            this.clientNo = clientNo;
        },
        updateDiag(item:any)
        {
            this.diags = [];
            this.diags.push(item);
        }
    },        
    // // 所有数据持久化
    // persist: true,
    //持久化存储插件其他配置
    persist: {
        // 修改存储中使用的键名称，默认为当前 Store的 id
        key: 'rx_key',
        // 修改为 sessionStorage，默认为 localStorage
        storage: window.localStorage,
        // 部分持久化状态的点符号路径数组，[]意味着没有状态被持久化(默认为undefined，持久化整个状态)
        // paths: ['token'],
    },
})