// src/store/index.ts
import { userStore } from '@/store/user';
import { chkItemStore } from '@/store/chkitem';
import { rxStore } from '@/store/rx';
import { pacsVideoStore } from '@/store/pacsVideo';
// import { useCounterStoreForSetup } from '@/store/counterStoreForSetup';

const appStore: any = {
};

/**
 * 注册app状态库
 */
export const registerStore = () => {
  appStore.userStore = userStore()
  appStore.chkItemStore = chkItemStore()
  appStore.rxStore = rxStore()
  appStore.pacsVideoStore = pacsVideoStore()
  //appStore.useCounterStoreForSetup = useCounterStoreForSetup();
};

export default appStore;